import { Currency, DiscountType } from "@lib";
import { IMacro, ISaleMacroOpenContext } from "../macro";
import { MacroAutocomplete } from "@core/controls/document-template-editor/document-template-macros-autocompleter";
import { WarrantyUtils } from "@/utils/types/warranty.utils";
import { companyMacroAutocomplete, CompanyMacroList } from "./company-macro-list";
import { Localizer } from "@/i18n/localizer";
import * as filters from "@/filters";
import { Printer } from "../printer";

export const saleMacroAutocomplete: MacroAutocomplete[] = [
    {
        type: "autocompleteitem",
        text: "Дата",
        value: "дата",
        nested: [{ type: "autocompleteitem", text: "Сегодня", value: "сегодня" }],
    },
    {
        type: "autocompleteitem",
        text: "Продажа",
        value: "продажа",
        nested: [
            { type: "autocompleteitem", text: "Номер", value: "номер" },
            { type: "autocompleteitem", text: "Дата", value: "дата" },
            { type: "autocompleteitem", text: "Сумма скидки", value: "суммаскидки" },
            { type: "autocompleteitem", text: "Комментарий", value: "комментарий" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Магазин",
        value: "магазин",
        nested: [
            { type: "autocompleteitem", text: "Номер", value: "номер" },
            { type: "autocompleteitem", text: "Название", value: "название" },
            { type: "autocompleteitem", text: "Описание", value: "описание" },
            { type: "autocompleteitem", text: "Телефон", value: "телефон" },
            { type: "autocompleteitem", text: "Почта", value: "почта" },
            { type: "autocompleteitem", text: "Адрес", value: "адрес" },
            { type: "autocompleteitem", text: "Валюта", value: "валюта" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Товары",
        value: "товары",
        nested: [
            { type: "autocompleteitem", text: "Количество наименований", value: "количествонаименований" },
            { type: "autocompleteitem", text: "Количество", value: "количество" },
            { type: "autocompleteitem", text: "Сумма", value: "сумма" },
            { type: "autocompleteitem", text: "К оплате", value: "коплате" },
            { type: "autocompleteitem", text: "Сумма прописью", value: "суммапрописью" },
            { type: "autocompleteitem", text: "К оплате прописью", value: "коплатепрописью" },
            { type: "autocompleteitem", text: "Дата гарантии", value: "датагарантии" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Клиент",
        value: "клиент",
        nested: [
            { type: "autocompleteitem", text: "Имя", value: "имя" },
            { type: "autocompleteitem", text: "Телефон", value: "телефон" },
            { type: "autocompleteitem", text: "Почта", value: "почта" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Продавец",
        value: "продавец",
        nested: [
            { type: "autocompleteitem", text: "Имя", value: "имя" },
            { type: "autocompleteitem", text: "ФИО", value: "фио" },
        ],
    },

    ...companyMacroAutocomplete,
];

export const SaleMacroList: IMacro<ISaleMacroOpenContext>[] = [
    // общее

    {
        alias: ["дата.сегодня", "date.now"],
        open: (context): string | undefined => filters.datetime.datetime(new Date().toUTCString(), "L"),
    },
    ...CompanyMacroList,

    // продажа

    {
        alias: ["продажа.номер", "sale.number"],
        open: (context): string | undefined => {
            const number = context.sale.number.toString();
            let template = context.shop?.settings?.numerationTemplate?.trim();

            if (template && template.length > 0) {
                // чтобы избежать рекурсии
                template = template.replace(/%\(Продажа.Номер\)/gi, number);
                return Printer.replaceSaleDocumentsGeneralMacros(template, context);
            }

            return number;
        },
    },
    {
        alias: ["продажа.дата", "sale.date"],
        open: ({ sale }): string | undefined => filters.datetime.datetime(sale.createdAt, "L"),
    },
    {
        alias: ["продажа.суммаскидки", "sale.discountvalue"],
        open: ({ sale, shop }): string | undefined => {
            const locale = shop?.info?.locale;

            if (!sale.info?.discount) {
                return filters.money.moneyFormat(0, { locale });
            }

            const discount = sale.info?.discount?.value ?? 0;

            if (discount === 0) {
                return filters.money.moneyFormat(discount, { locale });
            }

            if (sale.info.discount.type === DiscountType.Fixed) {
                return filters.money.moneyFormat(discount, { locale });
            }

            let sum = 0;
            if (sale.goods) {
                for (const good of sale.goods) {
                    sum += good.price * good.quantity;
                }
            }

            return filters.money.moneyFormat((sum * discount) / 100, { locale });
        },
    },
    {
        alias: ["продажа.комментарий", "sale.comment"],
        open: ({ sale }): string | undefined => sale.info?.comment,
    },

    // магазин

    {
        alias: ["магазин.номер", "shop.number"],
        open: ({ shop }): string | undefined => shop?.number?.toString(),
    },
    {
        alias: ["магазин.название", "shop.name"],
        open: ({ shop }): string | undefined => shop?.info.name,
    },
    {
        alias: ["магазин.описание", "shop.description"],
        open: ({ shop }): string | undefined => shop?.info.description,
    },
    {
        alias: ["магазин.телефон", "shop.phone"],
        open: ({ shop }): string | undefined => shop?.info.contacts?.phone,
    },
    {
        alias: ["магазин.адрес", "shop.address"],
        open: ({ shop }): string | undefined => shop?.info.contacts?.address,
    },
    {
        alias: ["магазин.почта", "shop.email"],
        open: ({ shop }): string | undefined => shop?.info.contacts?.email,
    },
    {
        alias: ["магазин.валюта", "shop.currency"],
        open: ({ shop }): string | undefined => Localizer.currencySymbol(shop?.info.currency ?? Currency.RUB),
    },

    // товары

    {
        alias: ["товары.количествонаименований", "goods.count"],
        open: ({ sale }): string | undefined => (sale.goods ? sale.goods.length.toString() : "0"),
    },
    {
        alias: ["товары.количество", "goods.quantity"],
        open: ({ sale }): string | undefined => {
            let quantity = 0;
            if (sale.goods) {
                for (const good of sale.goods) {
                    quantity += good.quantity;
                }
            }
            return quantity.toString();
        },
    },
    {
        alias: ["товары.сумма", "goods.sum"],
        open: ({ sale, shop }): string | undefined => {
            let sum = 0;
            if (sale.goods) {
                for (const good of sale.goods) {
                    sum += good.price * good.quantity;
                }
            }

            const locale = shop?.info?.locale;
            return filters.money.moneyFormat(sum, { locale });
        },
    },
    {
        alias: ["товары.коплате", "goods.forpayment"],
        open: ({ sale, shop }): string | undefined => {
            let sum = 0;
            if (sale.goods) {
                for (const good of sale.goods) {
                    sum += good.price * good.quantity;
                }
            }

            const locale = shop?.info?.locale;

            if (!sale.info?.discount) {
                return filters.money.moneyFormat(sum, { locale });
            }

            const discount = sale.info?.discount?.value ?? 0;
            if (discount === 0) {
                return filters.money.moneyFormat(sum, { locale });
            }

            if (sale.info.discount.type === DiscountType.Fixed) {
                return filters.money.moneyFormat(sum - discount, { locale });
            } else if (sale.info.discount.type === DiscountType.Percent) {
                return filters.money.moneyFormat(sum * (1 - discount / 100), { locale });
            }

            return filters.money.moneyFormat(sum, { locale });
        },
    },
    {
        alias: ["товары.суммапрописью", "goods.suminwords"],
        open: ({ sale, shop }): string | undefined => {
            let sum = 0;
            if (sale.goods) {
                for (const good of sale.goods) {
                    sum += good.price * good.quantity;
                }
            }

            const currency = shop?.info?.currency;
            return filters.money.moneyInWords(sum, currency);
        },
    },
    {
        alias: ["товары.коплатепрописью", "goods.forpaymentinwords"],
        open: ({ sale, shop }): string | undefined => {
            let sum = 0;
            if (sale.goods) {
                for (const good of sale.goods) {
                    sum += good.price * good.quantity;
                }
            }

            const currency = shop?.info?.currency;

            if (!sale.info?.discount) {
                return filters.money.moneyInWords(sum, currency);
            }

            const discount = sale.info?.discount?.value ?? 0;
            if (discount === 0) {
                return filters.money.moneyInWords(sum, currency);
            }

            if (sale.info.discount.type === DiscountType.Fixed) {
                return filters.money.moneyInWords(sum - discount, currency);
            } else if (sale.info.discount.type === DiscountType.Percent) {
                return filters.money.moneyInWords(sum * (1 - discount / 100), currency);
            }

            return filters.money.moneyInWords(sum, currency);
        },
    },
    {
        alias: ["товары.датагарантии", "товары.warrantydate"],
        open: ({ sale }): string => {
            const warranties = sale.goods?.map(w => w.warranty) ?? [];
            return WarrantyUtils.toMaxDateString(warranties);
        },
    },

    // клиент

    {
        alias: ["клиент.имя", "client.name"],
        open: ({ client }): string | undefined => client?.info.name,
    },
    {
        alias: ["клиент.телефон", "client.phone"],
        open: ({ client }): string | undefined => client?.info.contacts?.phone,
    },
    {
        alias: ["клиент.почта", "client.email"],
        open: ({ client }): string | undefined => client?.info.contacts?.email,
    },

    // продавец

    {
        alias: ["продавец.имя", "seller.name"],
        open: ({ seller }): string | undefined => seller?.info.name,
    },
    {
        alias: ["продавец.фио", "seller.fullname"],
        open: ({ seller }): string | undefined => seller?.info.officialName,
    },
];
