import { IOrder, DiscountType, PaymentType, BarcodeType, Currency } from "@lib";
import { IMacro, IOrderMacroOpenContext } from "../macro";
import { MacroAutocomplete } from "@core/controls/document-template-editor/document-template-macros-autocompleter";
import { WarrantyUtils } from "@/utils/types/warranty.utils";
import { IOrderMacroInfo } from "./order-table2.macro-list";
import { companyMacroAutocomplete, CompanyMacroList } from "./company-macro-list";
import { Localizer } from "@/i18n/localizer";
import * as filters from "@/filters";
import { Printer } from "../printer";
import { ApiBaseUrl } from "../../axios";

export const orderMacroAutocomplete: MacroAutocomplete[] = [
    {
        type: "autocompleteitem",
        text: "Дата",
        value: "дата",
        nested: [{ type: "autocompleteitem", text: "Сегодня", value: "сегодня" }],
    },
    {
        type: "autocompleteitem",
        text: "Заявка",
        value: "заявка",
        nested: [
            { type: "autocompleteitem", text: "Номер", value: "номер" },
            {
                type: "autocompleteitem",
                text: "Отслеживание",
                value: "Отслеживание",
                nested: [
                    { type: "autocompleteitem", text: "Код", value: "Код" },
                    { type: "autocompleteitem", text: "QR", value: "QR" },
                ],
            },
            { type: "autocompleteitem", text: "Дата", value: "дата" },
            { type: "autocompleteitem", text: "Дата завершения", value: "датазавершения" },
            { type: "autocompleteitem", text: "Дата оформления", value: "датаоформления" },
            { type: "autocompleteitem", text: "Дата исполнения", value: "датаисполнения" },
            { type: "autocompleteitem", text: "Название", value: "название" },
            { type: "autocompleteitem", text: "Описание", value: "описание" },
            { type: "autocompleteitem", text: "Предоплата", value: "предоплата" },
            { type: "autocompleteitem", text: "Сумма к оплате", value: "коплате" },
            { type: "autocompleteitem", text: "Скидка", value: "скидка" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Филиал",
        value: "филиал",
        nested: [
            { type: "autocompleteitem", text: "Номер", value: "номер" },
            { type: "autocompleteitem", text: "Название", value: "название" },
            { type: "autocompleteitem", text: "Описание", value: "описание" },
            { type: "autocompleteitem", text: "Телефон", value: "телефон" },
            { type: "autocompleteitem", text: "Почта", value: "почта" },
            { type: "autocompleteitem", text: "Адрес", value: "адрес" },
            { type: "autocompleteitem", text: "Валюта", value: "валюта" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Устройство",
        value: "устройство",
        nested: [
            { type: "autocompleteitem", text: "Тип", value: "тип" },
            { type: "autocompleteitem", text: "Артикул", value: "артикул" },
            { type: "autocompleteitem", text: "Название", value: "название" },
            { type: "autocompleteitem", text: "Неисправность", value: "неисправность" },
            { type: "autocompleteitem", text: "Бренд", value: "бренд" },
            { type: "autocompleteitem", text: "Модель", value: "модель" },
            { type: "autocompleteitem", text: "Серийный номер", value: "серийный" },
            { type: "autocompleteitem", text: "Комплектация", value: "комплектация" },
            { type: "autocompleteitem", text: "Внешний вид", value: "внешнийвид" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Работы",
        value: "работы",
        nested: [
            { type: "autocompleteitem", text: "Количество наименований", value: "количествонаименований" },
            { type: "autocompleteitem", text: "Количество", value: "количество" },
            { type: "autocompleteitem", text: "Сумма", value: "сумма" },
            { type: "autocompleteitem", text: "Список в строку", value: "списоквстроку" },
            { type: "autocompleteitem", text: "Список в столбец", value: "списоквстолбец" },
            { type: "autocompleteitem", text: "Дата гарантии", value: "датагарантии" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Материалы",
        value: "материалы",
        nested: [
            { type: "autocompleteitem", text: "Количество наименований", value: "количествонаименований" },
            { type: "autocompleteitem", text: "Количество", value: "количество" },
            { type: "autocompleteitem", text: "Сумма", value: "сумма" },
            { type: "autocompleteitem", text: "Список в строку", value: "списоквстроку" },
            { type: "autocompleteitem", text: "Список в столбец", value: "списоквстолбец" },
            { type: "autocompleteitem", text: "Дата гарантии", value: "датагарантии" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Работы и материалы",
        value: "работыматериалы",
        nested: [
            { type: "autocompleteitem", text: "Количество наименований", value: "количествонаименований" },
            { type: "autocompleteitem", text: "Количество", value: "количество" },
            { type: "autocompleteitem", text: "Сумма", value: "сумма" },
            { type: "autocompleteitem", text: "Сумма прописью", value: "суммапрописью" },
            { type: "autocompleteitem", text: "Дата гарантии", value: "датагарантии" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Клиент",
        value: "клиент",
        nested: [
            { type: "autocompleteitem", text: "Имя", value: "имя" },
            { type: "autocompleteitem", text: "Описание", value: "описание" },
            { type: "autocompleteitem", text: "ИНН", value: "инн" },
            { type: "autocompleteitem", text: "Телефон", value: "телефон" },
            { type: "autocompleteitem", text: "Почта", value: "почта" },
            { type: "autocompleteitem", text: "Скидка", value: "скидка" },
            { type: "autocompleteitem", text: "Комментарий", value: "комментарий" },
        ],
    },
    {
        type: "autocompleteitem",
        text: "Ответственный",
        value: "ответственный",
        nested: [
            { type: "autocompleteitem", text: "Имя", value: "имя" },
            { type: "autocompleteitem", text: "ФИО", value: "фио" },
        ],
    },

    ...companyMacroAutocomplete,
];

export function getOrderSummary(order: IOrder): IOrderMacroInfo {
    const info: IOrderMacroInfo = {
        works: order.works.reduce(
            (result, work) => {
                result.quantity += work.quantity;
                result.sum += work.quantity * work.price;

                return result;
            },
            { quantity: 0, sum: 0 },
        ),

        materials: order.materials.reduce(
            (result, material) => {
                result.quantity += material.quantity;
                result.expenses += material.cost * material.quantity;
                result.sum += material.quantity * material.price;

                return result;
            },
            { quantity: 0, expenses: 0, sum: 0 },
        ),

        payments: order.payments.reduce((result, payment) => {
            if (payment.type === PaymentType.Prepayment) {
                result += payment.value;
            }

            return result;
        }, 0),

        sum: 0.0,
        discount: 0.0,
    };

    info.sum = info.works.sum + info.materials.sum;

    if (order.info?.discount) {
        const disountTypes = {
            [DiscountType.Fixed]: order.info.discount.value ?? 0.0,
            [DiscountType.Percent]: (info.sum * order.info.discount.value) / 100.0,
        };

        info.discount = disountTypes[order.info.discount.type] ?? 0.0;
    }

    return info;
}

export const OrderMacroList: IMacro<IOrderMacroOpenContext>[] = [
    // общее

    {
        alias: ["дата.сегодня", "date.now"],
        open: (context): string | undefined => filters.datetime.datetime(new Date().toUTCString(), "L"),
    },
    ...CompanyMacroList,

    // заявка

    {
        alias: ["заявка.номер", "order.number"],
        open: (context): string | undefined => {
            const number = context.order.number.toString();
            let template = context.office?.settings?.numerationTemplate?.trim();

            if (template && template.length > 0) {
                // чтобы избежать рекурсии
                template = template.replace(/%\(Заявка.Номер\)/gi, number);
                return Printer.replaceOrderDocumentsGeneralMacros(template, context);
            }

            return number;
        },
    },
    {
        alias: ["заявка.отслеживание.код", "order.tracking.code"],
        open: (context): string | undefined => context.order.trackingCode,
    },
    {
        alias: ["заявка.отслеживание.qr", "order.tracking.qr"],
        open: (context, args?: string[]): string | undefined => {
            if (!context.order.trackingCode || context.order.trackingCode.length === 0) {
                return "";
            }

            // %(заявка.отслеживание.qr:120)
            const data = `${process.env.VUE_APP_WEBAPP_BASEURL}tracking/${context.order.trackingCode}`;
            const src = `${ApiBaseUrl}barcodes/?code=${data}&type=${BarcodeType.QRCODE}&showText=false`;
            const width = args && args.length >= 1 ? args[0] : undefined;

            return `<img
                src="${src}"
                width="${width}"
                alt="Штрихкод ${context.order.trackingCode}"
            />`;
        },
    },
    {
        alias: ["заявка.дата", "order.date"],
        open: ({ order }): string | undefined => filters.datetime.datetime(order.createdAt, "L"),
    },
    {
        alias: ["заявка.датазавершения", "order.donedate"],
        open: ({ order }): string | undefined =>
            order.doneAt ? filters.datetime.datetime(order.doneAt, "L") : undefined,
    },
    {
        alias: ["заявка.датаоформления", "order.createdate"],
        open: ({ order }): string | undefined =>
            order.info.createDate ? filters.datetime.datetime(order.info.createDate, "L") : undefined,
    },
    {
        alias: ["заявка.датаисполнения", "order.deadline"],
        open: ({ order }): string | undefined =>
            order.info.deadline ? filters.datetime.datetime(order.info.deadline, "L") : undefined,
    },
    {
        alias: ["заявка.название", "order.name"],
        open: ({ order }): string | undefined => order.info?.name,
    },
    {
        alias: ["заявка.описание", "order.description"],
        open: ({ order }): string | undefined => order.info?.description,
    },
    {
        alias: ["заявка.предоплата", "order.prepayment"],
        open: ({ order, office }): string | undefined => {
            const info = getOrderSummary(order);
            const sum = info.payments;

            const locale = office?.info?.locale;
            return filters.money.moneyFormat(sum, { locale });
        },
    },
    {
        alias: ["заявка.коплате", "order.forpayment"],
        open: ({ order, office }): string | undefined => {
            const info = getOrderSummary(order);
            const forpayment = info.sum - info.payments - info.discount;

            const locale = office?.info?.locale;
            return filters.money.moneyFormat(forpayment, { locale });
        },
    },
    {
        alias: ["заявка.скидка", "order.discount"],
        open: ({ order, office }): string | undefined => {
            if (order?.info?.discount && order.info.discount.value > 0) {
                if (order.info.discount.type === DiscountType.Fixed) {
                    const locale = office?.info?.locale;
                    return filters.money.moneyFormat(order.info.discount.value, { locale });
                } else if (order.info.discount.type === DiscountType.Percent) {
                    return `${order.info.discount.value}%`;
                }

                return "";
            }

            // const client = order?.clientRef;
            // if (!client?.info?.discount) return "";

            // if (client.info.discount.type === DiscountType.Fixed) {
            //     const locale = order?.officeRef?.info?.locale;
            //     return filters.money.moneyFormat(client.info.discount.value, { locale });
            // } else if (client.info.discount.type === DiscountType.Percent) {
            //     return `${client.info.discount.value}%`;
            // }

            return "";
        },
    },

    // филиал

    {
        alias: ["филиал.номер", "office.number"],
        open: ({ office }): string | undefined => office?.info.name,
    },
    {
        alias: ["филиал.название", "office.name"],
        open: ({ office }): string | undefined => office?.info.name,
    },
    {
        alias: ["филиал.описание", "office.description"],
        open: ({ office }): string | undefined => office?.info.description,
    },
    {
        alias: ["филиал.телефон", "office.phone"],
        open: ({ office }): string | undefined => office?.info.contacts?.phone,
    },
    {
        alias: ["филиал.адрес", "office.address"],
        open: ({ office }): string | undefined => office?.info.contacts?.address,
    },
    {
        alias: ["филиал.почта", "office.email"],
        open: ({ office }): string | undefined => office?.info.contacts?.email,
    },
    {
        alias: ["филиал.валюта", "office.currency"],
        open: ({ office }): string | undefined => Localizer.currencySymbol(office?.info.currency ?? Currency.RUB),
    },

    // устройство

    {
        alias: ["устройство.тип", "product.type"],
        open: ({ order, productTypes }): string | undefined => {
            const typeId = order.products && order.products.length > 0 ? order.products[0]?.info.type : undefined;
            const type = productTypes?.find(t => t.id === typeId);
            return type?.name;
        },
    },
    {
        alias: ["устройство.артикул", "product.sku"],
        open: ({ order }): string | undefined =>
            order.products && order.products.length > 0 ? order.products[0]?.info.sku : undefined,
    },
    {
        alias: ["устройство.название", "product.name"],
        open: ({ order }): string | undefined =>
            order.products && order.products.length > 0 ? order.products[0]?.info.name : undefined,
    },
    {
        alias: ["устройство.неисправность", "product.description"],
        open: ({ order }): string | undefined =>
            order.products && order.products.length > 0 ? order.products[0]?.info.description : undefined,
    },
    {
        alias: ["устройство.бренд", "product.brand"],
        open: ({ order }): string | undefined =>
            order.products && order.products.length > 0 ? order.products[0]?.info.brand : undefined,
    },
    {
        alias: ["устройство.модель", "product.model"],
        open: ({ order }): string | undefined =>
            order.products && order.products.length > 0 ? order.products[0]?.info.model : undefined,
    },
    {
        alias: ["устройство.серийный", "product.serial"],
        open: ({ order }): string | undefined =>
            order.products && order.products.length > 0 ? order.products[0]?.info.serial : undefined,
    },
    {
        alias: ["устройство.комплектация", "product.suite"],
        open: ({ order }): string | undefined =>
            order.products && order.products.length > 0 ? order.products[0]?.info.suite : undefined,
    },
    {
        alias: ["устройство.внешнийвид", "product.appearance"],
        open: ({ order }): string | undefined =>
            order.products && order.products.length > 0 ? order.products[0]?.info.appearance : undefined,
    },

    // работы

    {
        alias: ["работы.количествонаименований", "works.count"],
        open: ({ order }): string | undefined => (order.works ? order.works.length.toString() : "0"),
    },
    {
        alias: ["работы.количество", "works.quantity"],
        open: ({ order }): string => {
            let quantity = 0;
            if (order.works) {
                for (const work of order.works) {
                    quantity += work.quantity;
                }
            }
            return quantity.toString();
        },
    },
    {
        alias: ["работы.сумма", "works.sum"],
        open: ({ order, office }): string | undefined => {
            let sum = 0;
            if (order.works) {
                for (const work of order.works) {
                    sum += work.price * work.quantity;
                }
            }

            const locale = office?.info?.locale;
            return filters.money.moneyFormat(sum, { locale });
        },
    },
    {
        alias: ["работы.списоквстроку", "works.listinline"],
        open: ({ order }): string => {
            let list = "";
            if (order.works) {
                for (const work of order.works) {
                    if (list.length > 0) {
                        list += ", ";
                    }
                    list += work.name;
                }
            }
            return list;
        },
    },
    {
        alias: ["работы.списоквстолбец", "works.listincolumn"],
        open: ({ order }): string => {
            let list = "";
            if (order.works) {
                for (const work of order.works) {
                    if (list.length > 0) {
                        list += "<br/>";
                    }
                    list += work.name;
                }
            }
            return list;
        },
    },
    {
        alias: ["работы.датагарантии", "works.warrantydate"],
        open: ({ order }): string => {
            const warranties = order.works?.map(w => w.warranty) ?? [];
            return WarrantyUtils.toMaxDateString(warranties);
        },
    },

    // материалы

    {
        alias: ["материалы.количествонаименований", "materials.count"],
        open: ({ order }): string | undefined => (order.materials ? order.materials.length.toString() : "0"),
    },
    {
        alias: ["материалы.количество", "materials.quantity"],
        open: ({ order }): string | undefined => {
            let quantity = 0;
            if (order.materials) {
                for (const material of order.materials) {
                    quantity += material.quantity;
                }
            }
            return quantity.toString();
        },
    },
    {
        alias: ["материалы.сумма", "materials.sum"],
        open: ({ order, office }): string | undefined => {
            let sum = 0;
            if (order.materials) {
                for (const material of order.materials) {
                    sum += material.price * material.quantity;
                }
            }

            const locale = office?.info?.locale;
            return filters.money.moneyFormat(sum, { locale });
        },
    },
    {
        alias: ["материалы.списоквстроку", "materials.listinline"],
        open: ({ order }): string => {
            let list = "";
            if (order.materials) {
                for (const material of order.materials) {
                    if (list.length > 0) {
                        list += ", ";
                    }
                    list += material.name;
                }
            }
            return list;
        },
    },
    {
        alias: ["материалы.списоквстолбец", "materials.listincolumn"],
        open: ({ order }): string => {
            let list = "";
            if (order.materials) {
                for (const material of order.materials) {
                    if (list.length > 0) {
                        list += "<br/>";
                    }
                    list += material.name;
                }
            }
            return list;
        },
    },
    {
        alias: ["материалы.датагарантии", "materials.warrantydate"],
        open: ({ order }): string => {
            const warranties = order.materials?.map(w => w.warranty) ?? [];
            return WarrantyUtils.toMaxDateString(warranties);
        },
    },

    // работы и материалы

    {
        alias: ["работыматериалы.количествонаименований", "worksmaterials.count"],
        open: ({ order }): string | undefined =>
            ((order.works ? order.works.length : 0) + (order.materials ? order.materials.length : 0)).toString(),
    },
    {
        alias: ["работыматериалы.количество", "worksmaterials.quantity"],
        open: ({ order }): string | undefined => {
            let quantity = 0;
            if (order.works) {
                for (const work of order.works) {
                    quantity += work.quantity;
                }
            }
            if (order.materials) {
                for (const material of order.materials) {
                    quantity += material.quantity;
                }
            }
            return quantity.toString();
        },
    },
    {
        alias: ["работыматериалы.сумма", "worksmaterials.sum"],
        open: ({ order, office }): string | undefined => {
            let sum = 0;
            if (order.works) {
                for (const work of order.works) {
                    sum += work.price * work.quantity;
                }
            }
            if (order.materials) {
                for (const material of order.materials) {
                    sum += material.price * material.quantity;
                }
            }

            const locale = office?.info?.locale;
            return filters.money.moneyFormat(sum, { locale });
        },
    },
    {
        alias: ["работыматериалы.суммапрописью", "worksmaterials.suminwords"],
        open: ({ order, office }): string | undefined => {
            let sum = 0;
            if (order.works) {
                for (const work of order.works) {
                    sum += work.price * work.quantity;
                }
            }
            if (order.materials) {
                for (const material of order.materials) {
                    sum += material.price * material.quantity;
                }
            }

            const currency = office?.info?.currency;
            return filters.money.moneyInWords(sum, currency);
        },
    },
    {
        alias: ["работыматериалы.датагарантии", "worksmaterials.warrantydate"],
        open: ({ order }): string => {
            const workWarranties = order.works?.map(w => w.warranty) ?? [];
            const materialWarranties = order.materials?.map(w => w.warranty) ?? [];
            const warranties = [...workWarranties, ...materialWarranties];
            return WarrantyUtils.toMaxDateString(warranties);
        },
    },

    // клиент

    // {
    //     alias: ["клиент.тип", "client.type"],
    //     open: (order: IOrder): string | undefined => order.clientRef?.info.type,
    // },
    {
        alias: ["клиент.имя", "client.name"],
        open: ({ client }): string | undefined => client?.info.name,
    },
    {
        alias: ["клиент.описание", "client.description"],
        open: ({ client }): string | undefined => client?.info.description,
    },
    {
        alias: ["клиент.инн", "client.taxid"],
        open: ({ client }): string | undefined => client?.info.taxId,
    },
    {
        alias: ["клиент.телефон", "client.phone"],
        open: ({ client }): string | undefined => client?.info.contacts?.phone,
    },
    {
        alias: ["клиент.почта", "client.email"],
        open: ({ client }): string | undefined => client?.info.contacts?.email,
    },
    {
        alias: ["клиент.скидка", "клиент.discount"],
        open: ({ client, office }): string | undefined => {
            if (!client?.info?.discount) {
                return "";
            }

            if (client.info.discount.type === DiscountType.Fixed) {
                const locale = office?.info?.locale;
                return filters.money.moneyFormat(client.info.discount.value, { locale });
            } else if (client.info.discount.type === DiscountType.Percent) {
                return `${client.info.discount.value}%`;
            }

            return "";
        },
    },
    // {
    //     alias: ["клиент.источник", "client.source"],
    //     open: (order: IOrder): string | undefined => order.clientRef?.info.source,
    // },
    {
        alias: ["клиент.комментарий", "client.comment"],
        open: ({ client }): string | undefined => client?.comment,
    },

    // ответственный

    {
        alias: ["ответственный.имя", "manager.name"],
        open: ({ manager }): string | undefined => manager?.info.name,
    },
    {
        alias: ["ответственный.фио", "manager.fullname"],
        open: ({ manager }): string | undefined => manager?.info.officialName,
    },
];
