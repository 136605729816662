import { Component, Vue } from "vue-property-decorator";
import {
    IGoodRegistration,
    IGoodRegistrationUpdateDto,
    ISelectQuery,
    ISettingsTableUpdateDto,
    PermissionType,
    ITableFilter,
    ITableSort,
    TableType,
    PermissionRight,
    IGoodCategory,
    IUser,
    ICompany,
    IStore,
    IAccount,
    IGood,
    ITemplateLabel,
    ISupplier,
    IEmployee,
    ICompanyFeatureBarcodes,
    IReadQuery,
} from "@lib";
import AltTable, { TableApi } from "@/core/components/alt-table";
import { Modal2Component } from "@core/components/alt-ui/modal-2/modal-2.component";
import { Button, Html, Icon } from "@core/components/alt-ui/controls";
import { getDefaultTableColumns } from "./good-registrations-defaults";
import GoodRegistrationsToolbar from "./good-registrations-toolbar.vue";
import { convertFilterBack } from "./good-registrations-filter-defaults";
import { GoodRegistrationModal } from "./modals/good-registration.modal";
import { StoresDataLoader } from "../stores-data-loader";

@Component({
    name: "good-registrations",
    components: {
        AltTable,
        GoodRegistrationsToolbar,
        Modal2Component,
    },
})
export default class GoodRegistrations extends Vue {
    private GoodRegistrationUseCase = this.$alt.system.usecase.createGoodRegistrationUseCase();
    private SettingsTableUseCase = this.$alt.system.usecase.createSettingsTableUseCase();
    private BarcodesUseCase = this.$alt.system.usecase.createCompanyFeatureBarcodesUseCase();

    private DataLoader = new StoresDataLoader(this.$alt, this.$info, this.$settings);

    private loaded: boolean = true;
    private user!: IUser;
    private company!: ICompany;
    private barcodesFeature: ICompanyFeatureBarcodes | null = null;
    private goodRegistrations: IGoodRegistration[] = [];
    private goodRegistrationsTotal: number = 0;
    private stores: IStore[] = [];
    private accounts: IAccount[] = [];
    private categories: IGoodCategory[] = [];
    private suppliers: ISupplier[] = [];
    private employees: IEmployee[] = [];
    private labelTemplates: ITemplateLabel[] = [];

    private goodRegistrationModal: GoodRegistrationModal;

    private table: any = {
        type: TableType.GoodRegistration,
        settings: {
            limit: undefined,
            columns: [],
            sort: [],
            filter: [],
        },
        page: 1,
        search: "",
        api: new TableApi(),
    };

    public constructor() {
        super();

        this.goodRegistrationModal = new GoodRegistrationModal("good-registration-modal.gr");
        this.goodRegistrationModal.addVisibleChangedHandler(handler => {
            if (handler.visible) {
                const context = handler.context;

                if (context.goodRegistration) {
                    this.$router.push({ query: { id: context.goodRegistration.id } }).catch(() => {});
                } else {
                    this.$router.push({ query: { new: "" } }).catch(() => {});
                }
            } else {
                this.$router.push({ query: undefined }).catch(() => {});
            }
        });
    }

    private get tableType(): TableType {
        return TableType.GoodRegistration;
    }

    private get defaulColumns(): any[] {
        return getDefaultTableColumns(this);
    }

    private get goodRegistrationsData(): IGoodRegistration[] {
        return this.goodRegistrations ? this.goodRegistrations : [];
    }

    private get goodRegistrationsDataTotal(): number {
        return this.goodRegistrationsTotal ? this.goodRegistrationsTotal : 0;
    }

    private get can(): any {
        const secure = this.$secure;
        const stores = this.stores;

        return {
            get read(): boolean {
                return true;
            },
            get update(): Function {
                return (goodRegistration: IGoodRegistration): boolean => {
                    return secure.check(PermissionType.Stores, goodRegistration.store, PermissionRight.GoodsUpdate);
                };
            },
            get delete(): Function {
                return (goodRegistration: IGoodRegistration): boolean => {
                    return secure.check(PermissionType.Stores, goodRegistration.store, PermissionRight.GoodsDelete);
                };
            },
            get create(): boolean {
                for (const store of stores) {
                    const valid = secure.check(PermissionType.Stores, store.id, PermissionRight.GoodsCreate);
                    if (valid) {
                        return true;
                    }
                }
                return false;
            },
        };
    }

    private get columns(): any[] {
        return TableApi.prepareColumns(this.defaulColumns, this.table.settings?.columns ?? []);
    }

    private get limit(): number {
        return this.table.settings?.limit ?? TableApi.DefaultLimit;
    }

    private get skip(): number {
        return (this.table.page - 1) * this.limit;
    }

    private get sort(): ITableSort[] {
        return this.table.settings?.sort ?? [];
    }

    private get filter(): ITableFilter[] {
        return this.table.settings?.filter ?? [];
    }

    public async mounted(): Promise<void> {
        try {
            this.$alt.loader.show();
            this.loaded = false;
            this.user = await this.$info.getUser();
            this.company = await this.$info.getCompany();
            this.stores = await this.$info.getStores();
            this.accounts = await this.$info.getAccounts();
            this.employees = await this.$info.getEmployees();
            await this.DataLoader.init();
            await this.initData();
            await this.initParams();
            this.loaded = true;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    public beforeDestroy(): void {
        this.$info.ui.cleanHeaderControls();
    }

    private async initData(): Promise<void> {
        [this.table.settings, this.categories, this.suppliers, this.labelTemplates, this.barcodesFeature] =
            await Promise.all([
                this.DataLoader.selectTableSettings(this.table.type),
                this.DataLoader.selectAllCategories(),
                this.DataLoader.selectSuppliers(),
                this.DataLoader.selectLabelTemplates(),
                this.DataLoader.getBarcodesFeature(),
            ]);

        const filter = {
            store: this.stores.map(e => e.id),
        };

        if (!this.table.settings) {
            this.table.settings = {};
        }

        this.table.settings.filter = convertFilterBack(filter);

        this.initHeader();

        await this.selectData(this.skip, this.limit, this.sort, this.filter);
    }

    private initHeader(): void {
        const hdrTitle = new Html();
        hdrTitle.id = "good-registrations.header-title";
        hdrTitle.html = '<h2 class="m-0">Оприходования</h2>';

        const hdrRefresh = new Button();
        hdrRefresh.id = "good-registrations.header-refresh";
        hdrRefresh.variant = "flat-dark";
        hdrRefresh.class = "p-0.5 mx-0.5";
        hdrRefresh.help = "Обновить";
        hdrRefresh.icon = new Icon();
        hdrRefresh.icon.icon = "RefreshCwIcon";
        hdrRefresh.addClickHandler(() => this.refreshData());

        this.$info.ui.setHeaderControls([hdrTitle, hdrRefresh]);
    }

    private async initParams(): Promise<void> {
        try {
            if (this.$route.query.new !== undefined) {
                this.showModalCreate();
                return;
            }

            if (this.$route.query.id) {
                const registrationId =
                    this.$route.query.id instanceof Array ? (this.$route.query.id[0] as string) : this.$route.query.id;
                const registration = await this.getGoodRegistration(registrationId);
                this.showModalUpdate(registration);
            }
        } catch (e: any) {
            await this.$router.push({ query: undefined }).catch(_ => {});
            throw new Error("Оприходование не найдено.");
        }
    }

    private async updateFilter(): Promise<void> {
        const dto: ISettingsTableUpdateDto = { filter: this.filter };
        await this.SettingsTableUseCase.update(this.company.id, this.user.id, TableType.Good, dto);
    }

    private async refreshData(): Promise<void> {
        try {
            this.$alt.loader.show();
            await this.initData();
            this.$alt.toast.success("Данные обновлены.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async changeTableColumns(columns: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { columns };
            this.table.settings = await this.SettingsTableUseCase.update(
                this.company.id,
                this.user.id,
                this.tableType,
                dto,
            );
        } catch {}
    }

    private async sortData(sort: any[]): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { sort };
            this.table.settings = await this.SettingsTableUseCase.update(
                this.company.id,
                this.user.id,
                this.tableType,
                dto,
            );
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changeLimit(limit: number, page: number): Promise<void> {
        try {
            const dto: ISettingsTableUpdateDto = { limit };
            this.table.settings = await this.SettingsTableUseCase.update(
                this.company.id,
                this.user.id,
                this.tableType,
                dto,
            );
            this.table.page = page;
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch {}
    }

    private async changePage(page: number): Promise<void> {
        try {
            this.table.page = page;

            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async showModalCreate(): Promise<void> {
        const labelTemplatesIds = await this.$settings.getLabelsGoodRegistrationCreate();
        const labelTemplatesSelected = this.labelTemplates.filter(d => labelTemplatesIds.includes(d.id));
        const deductFromAccount = await this.$settings.getDeductFromAccountGoodRegistrationCreate();
        const accountId = await this.$settings.getAccountGoodRegistrationCreate();

        const success = await this.goodRegistrationModal.show({
            companyId: this.company.id,
            userId: this.user.id,
            stores: this.stores,
            store: this.stores.length > 0 ? this.stores[0] : undefined,
            accounts: this.accounts,
            suppliers: this.suppliers,
            categories: this.categories,
            labelTemplates: this.labelTemplates,
            labelTemplatesSelected: labelTemplatesSelected,
            showBarcode: this.company.features?.barcodes?.enabledForGoods ?? false,
            message: this.$alt.message,
            isDeductFromAccountSelected: deductFromAccount,
            selectedAccountId: accountId,
            barcodesFeature: this.barcodesFeature,
            barcodesUseCase: this.BarcodesUseCase,
            searchGoods: (search, storeId) => this.searchGoods(search, storeId),
            createHandler: (dto, labels, deductFromAccount, account) =>
                this.$alt.longOperation(async () => {
                    const registration = await this.GoodRegistrationUseCase.create(this.company.id, dto);
                    this.DataLoader.saveSelectedLabels(labels);
                    this.DataLoader.saveSelectedDeductFromAccount(deductFromAccount);
                    if (deductFromAccount && account) {
                        this.DataLoader.saveSelectedAccount(account);
                    }
                    this.DataLoader.printLabelsForGoodRegistration(labels, registration);
                }, "Оприходование успешно создано."),
        });

        if (success) {
            await this.$alt.longOperation(() => this.selectData(this.skip, this.limit, this.sort, this.filter));
        }
    }

    public async showModalUpdate(goodRegistration: IGoodRegistration): Promise<void> {
        const labelTemplatesIds = await this.$settings.getLabelsGoodRegistrationCreate();
        const deductFromAccount = await this.$settings.getDeductFromAccountGoodRegistrationCreate();
        const accountId = await this.$settings.getAccountGoodRegistrationCreate();
        const labelTemplatesSelected = this.labelTemplates.filter(d => labelTemplatesIds.includes(d.id));

        const success = await this.goodRegistrationModal.show({
            companyId: this.company.id,
            userId: this.user.id,
            stores: this.stores,
            store: this.stores.length > 0 ? this.stores[0] : undefined,
            accounts: this.accounts,
            suppliers: this.suppliers,
            categories: this.categories,
            labelTemplates: this.labelTemplates,
            labelTemplatesSelected: labelTemplatesSelected,
            showBarcode: this.company.features?.barcodes?.enabledForGoods ?? false,
            goodRegistration,
            message: this.$alt.message,
            isDeductFromAccountSelected: deductFromAccount,
            selectedAccountId: accountId,
            barcodesFeature: this.barcodesFeature,
            barcodesUseCase: this.BarcodesUseCase,
            searchGoods: (search, storeId) => this.searchGoods(search, storeId),
            printHandler: (labels, registration) =>
                this.DataLoader.printLabelsForGoodRegistration(labels, registration),
        });

        if (success) {
            await this.$alt.longOperation(() => this.selectData(this.skip, this.limit, this.sort, this.filter));
        }
    }

    private async confirmDelete(goodRegistration: IGoodRegistration): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Вы уверены, что хотите удалить оприходование: #${goodRegistration.number}?`,
            "Удаление оприходования",
            { acceptText: "Удалить" },
        );

        if (result) {
            await this.delete(goodRegistration);
        }
    }

    // private async confirmDeleteMany(goodRegistrations: IGoodRegistration[]): Promise<void> {
    //     const result = await this.$alt.message.confirm(
    //         `Вы уверены, что хотите удалить ${goodRegistrations.length} оприходований?`,
    //         "Удаление оприходований",
    //         { acceptText: "Удалить" },
    //     );

    //     if (result) {
    //         await this.deleteMany(goodRegistrations);
    //     }
    // }

    private async selectData(
        offset: number,
        limit: number,
        tsort: ITableSort[],
        tfilter: ITableFilter[],
    ): Promise<void> {
        try {
            if (!this.can.read) {
                return;
            }

            const filter = this.$alt.system.query.convertTableFilter(tfilter);
            const sort = this.$alt.system.query.convertTableSort(tsort);
            const search = this.table.search.length > 0 ? this.table.search : undefined;
            const query: ISelectQuery = { limit, offset, sort, ...filter, search };
            const result = await this.GoodRegistrationUseCase.select(this.company.id, query);

            this.goodRegistrations = result.data;
            this.goodRegistrationsTotal = result.total;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить оприходования:\n${e.message}`);
        }
    }

    private async searchGoods(search: string, storeId: string): Promise<IGood[]> {
        try {
            const query: IReadQuery = {
                search,
                limit: 10,
                offset: 0,
            };
            const result = await this.$alt.system.usecase
                .createGoodUseCase()
                .selectForStore(this.company.id, storeId, query);

            return result.data;
        } catch {
            return [];
        }
    }

    private async searchData(search: string): Promise<void> {
        try {
            this.table.search = search.trim().length > 1 ? search : "";

            await this.selectData(this.skip, this.limit, this.sort, this.filter);
        } catch (e: any) {
            this.goodRegistrations = [];
            this.goodRegistrationsTotal = 0;
        }
    }

    private async getGoodRegistration(id: string): Promise<any> {
        try {
            const goodRegistration = await this.GoodRegistrationUseCase.get(this.company.id, id);
            if (!goodRegistration) {
                throw new Error("Оприходование не найдено.");
            }

            return goodRegistration;
        } catch (e: any) {
            throw new Error(`Не удалось загрузить данные оприходования:\n${e.message}`);
        }
    }

    private async update(orig: IGoodRegistration, dto: IGoodRegistrationUpdateDto): Promise<IGoodRegistration | null> {
        try {
            this.$alt.loader.show();
            const registration = await this.GoodRegistrationUseCase.update(this.company.id, orig.id, dto);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);
            this.$alt.toast.success("Оприходование успешно изменено.");
            return registration;
        } catch (e: any) {
            this.$alt.toast.error(e.message);
            return null;
        } finally {
            this.$alt.loader.hide();
        }
    }

    private async delete(goodRegistration: IGoodRegistration): Promise<void> {
        try {
            this.$alt.loader.show();

            await this.GoodRegistrationUseCase.delete(this.company.id, goodRegistration.id);
            await this.selectData(this.skip, this.limit, this.sort, this.filter);

            this.$alt.toast.success("Оприходование успешно удалено.");
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    // private async deleteMany(goodRegistrations: IGoodRegistration[]): Promise<void> {
    //     try {
    //         this.$alt.loader.show();

    //         const dto: IClientDeleteManyDto = {
    //             ids: goodRegistrations.map(e => e.id),
    //         };

    //         await this.GoodRegistrationUseCase.deleteMany(this.company.id, dto);
    //         await this.selectData(this.skip, this.limit, this.sort, this.filter);
    //         this.$alt.toast.success("Клиенты успешно удалены.");
    //     } catch (e: any) {
    //         this.$alt.toast.error(e.message);
    //     } finally {
    //         this.$alt.loader.hide();
    //     }
    // }
}
