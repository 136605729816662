import {
    IOrderTypeField,
    IClient,
    IOrderTypeFieldGroup,
    IOffice,
    ITemplateDocument,
    OrderTypeFieldGroupType,
    IOrder,
    IOrderType,
    IProductType,
    ICompany,
} from "@lib";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { IMacro, IMacroReplacer, IOrderMacroOpenContext } from "../macro";
import { OrderMacroList } from "./order-document.macro-list";

export type OrderPrintContext = {
    company: ICompany;
    office: IOffice;
    order: IOrder;
    orderType: IOrderType;
    productTypes: IProductType[];
};

export type GroupedCustomField = {
    field: IOrderTypeField;
    group: IOrderTypeFieldGroup;
};

export class OrderDocumentMacroReplacer implements IMacroReplacer<ITemplateDocument> {
    // %(макрос:аргумент1,аргумент2)
    private readonly regexp = /%\((?<macro>[\p{L}\p{N}.]*)(:?(?<args>[\p{L}\p{N}.,]*)?)\)/giu;
    // \p{L} - юникодные буквы
    // \p{N} - юникодные цифры
    // регулярки: https://developer.mozilla.org/ru/docs/Web/JavaScript/Guide/Regular_Expressions
    // юникодные символы: https://learn.javascript.ru/regexp-unicode
    // проверка регулярок: https://regex101.com/

    private readonly customFields: GroupedCustomField[];
    private readonly context: OrderPrintContext;

    public constructor(context: OrderPrintContext) {
        this.context = context;
        this.customFields = this.getCustomFields();
    }

    private getCustomFields(): GroupedCustomField[] {
        const fields: GroupedCustomField[] = [];

        if (!this.context.orderType) {
            return fields;
        }

        for (const group of this.context.orderType.groups) {
            for (const field of group.fields) {
                if (field.custom) {
                    fields.push({ field, group });
                }
            }
        }

        return fields;
    }

    public replace(model: ITemplateDocument): string {
        return this.replaceSimple(model.template, model);
    }

    public replaceSimple(template: string, model?: ITemplateDocument): string {
        return this.replaceRegexp(template, value => value);
    }

    protected replaceRegexp(template: string, func: (value: string) => string): string {
        return template.replace(this.regexp, (match: string, macro: string, args: string) => {
            try {
                const argsArray = args.length > 0 ? args.substring(1).split(",") : undefined;
                const value = this.replaceMacro(macro, argsArray) ?? this.replaceCustomMacro(macro) ?? match;
                return func(value);
            } catch {
                //console.warn(`Не удалось раскрыть макрос: ${match}.`);
                return match;
            }
        });
    }

    private replaceMacro(macroName: string, args?: string[]): string | null {
        const macro = this.findMacro(macroName);
        if (!macro) {
            return null;
        }

        const order = this.context.order;

        const context: IOrderMacroOpenContext = {
            company: this.context.company,
            office: this.context.office,
            order: order,
            orderType: this.context.orderType,
            productTypes: this.context.productTypes,
            client: order.clientRef,
            manager: order.managerRef,
        };

        return macro.open(context, args) ?? "";
    }

    private findMacro(macroName: string): IMacro<IOrderMacroOpenContext> | null {
        macroName = macroName.toLowerCase();

        for (const macro of OrderMacroList) {
            for (const alias of macro.alias) {
                if (alias.toLowerCase() === macroName) {
                    return macro;
                }
            }
        }

        return null;
    }

    private replaceCustomMacro(macroName: string): string | null {
        const customField = this.findCustomFieldByMacro(macroName);
        if (!customField) {
            return null;
        }

        const order = this.context.order;
        const locale = order.officeRef?.info?.locale;
        const field = customField.field;

        // TODO: повтор, как в orders-defaults
        const key = field.customId ?? field.details1 ?? field.id;
        let value: any;

        if (customField.group.type === OrderTypeFieldGroupType.Client && order.client) {
            const client = order.clientRef;
            value = client?.custom ? client.custom[key] : undefined;
        } else if (customField.group.type === OrderTypeFieldGroupType.Product && order.products?.length > 0) {
            const product = order.products[0];
            value = product.custom ? product.custom[key] : undefined;
        } else {
            value = order.custom ? order.custom[key] : undefined;
        }

        return FieldControlFactory.formatValue(value, field, { locale });
    }

    private findCustomFieldByMacro(macroName: string): GroupedCustomField | null {
        macroName = macroName.toLowerCase();

        for (const customField of this.customFields) {
            if (!customField.field.macros) {
                continue;
            }

            for (const alias of customField.field.macros) {
                if (alias.trim().toLowerCase() === macroName) {
                    return customField;
                }
            }
        }

        return null;
    }
}

export class OrderDocumentMacroHtmlReplacer extends OrderDocumentMacroReplacer {
    public constructor(context: OrderPrintContext) {
        super(context);
    }

    public replaceSimple(template: string, model?: ITemplateDocument): string {
        return this.replaceRegexp(template, value => `<span style="white-space: pre-wrap;">${value}</span>`);
    }
}
