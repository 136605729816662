import { ISale, Currency, Locale } from "@lib";
import { IIterableMacro, IMacro } from "../macro";
import { WarrantyUtils } from "@/utils/types/warranty.utils";
import * as filters from "@/filters";

export interface ISaleTableContext {
    sale: ISale;
    currency: Currency;
    locale: Locale;
    info: ISaleTableContextInfo;
}

export interface ISaleTableIterableContext {
    sale: ISale;
    locale: Locale;
    meta: {
        iterator: number;
    };
}

export interface ISaleTableContextInfo {
    goods: {
        quantity: number;
        sum: number;
    };

    payments: number;
    discount: number;
}

export const SaleTable2IterableMacroList: IIterableMacro<ISaleTableIterableContext>[] = [
    {
        alias: ["т.номер", "товары.номер"],
        open: (context: ISaleTableIterableContext) => {
            const value = context.sale.goods.length ? (context.meta.iterator + 1).toString() : "";

            return {
                hasNextRow: !!context.sale.goods[context.meta.iterator + 1],
                value,
            };
        },
    },
    {
        alias: ["т.артикул", "товары.артикул"],
        open: (context: ISaleTableIterableContext) => {
            const current = context.sale.goods[context.meta.iterator];
            const next = context.sale.goods[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current?.sku ?? "",
            };
        },
    },
    {
        alias: ["т.наименование", "товары.наименование"],
        open: (context: ISaleTableIterableContext) => {
            const current = context.sale.goods[context.meta.iterator];
            const next = context.sale.goods[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? current.name : "",
            };
        },
    },
    {
        alias: ["т.гарантия", "товары.гарантия"],
        open: (context: ISaleTableIterableContext) => {
            const current = context.sale.goods[context.meta.iterator];
            const next = context.sale.goods[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? WarrantyUtils.toString(current.warranty) : "",
            };
        },
    },
    {
        alias: ["т.дата-гарантии", "товары.дата-гарантии", "т.датагарантии", "товары.датагарантии"],
        open: (context: ISaleTableIterableContext) => {
            const current = context.sale.goods[context.meta.iterator];
            const next = context.sale.goods[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? WarrantyUtils.toMaxDateString([current.warranty]) : "",
            };
        },
    },
    {
        alias: ["т.количество", "товары.количество"],
        open: (context: ISaleTableIterableContext) => {
            const current = context.sale.goods[context.meta.iterator];
            const next = context.sale.goods[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? current.quantity.toString() : "",
            };
        },
    },
    {
        alias: ["т.себестоимость", "товары.себестоимость"],
        open: (context: ISaleTableIterableContext) => {
            const current = context.sale.goods[context.meta.iterator];
            const next = context.sale.goods[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? filters.money.moneyFormat(current.cost, { locale: context.locale }) : "",
            };
        },
    },
    {
        alias: ["т.цена", "товары.цена"],
        open: (context: ISaleTableIterableContext) => {
            const current = context.sale.goods[context.meta.iterator];
            const next = context.sale.goods[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current ? filters.money.moneyFormat(current.price, { locale: context.locale }) : "",
            };
        },
    },
    {
        alias: ["т.сумма", "товары.сумма"],
        open: (context: ISaleTableIterableContext) => {
            const current = context.sale.goods[context.meta.iterator];
            const next = context.sale.goods[context.meta.iterator + 1];

            return {
                hasNextRow: !!next,
                value: current
                    ? filters.money.moneyFormat(current.quantity * current.price, { locale: context.locale })
                    : "",
            };
        },
    },
];

export const SaleTable2MacroList: IMacro<ISaleTableContext>[] = [
    {
        alias: ["т.итого", "товары.итого"],
        open: (context: ISaleTableContext): string | undefined => {
            const value = context.info.goods.sum;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["т.скидка", "товары.скидка"],
        open: (context: ISaleTableContext): string | undefined => {
            const value = context.info.discount;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["т.итого-без-скидки", "товары.итого-без-скидки"],
        open: (context: ISaleTableContext): string | undefined => {
            if (!context.info.discount) {
                return undefined;
            }

            const value = context.info.goods.sum - context.info.discount;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["т.предоплата", "товары.предоплата"],
        open: (context: ISaleTableContext): string | undefined => {
            const value = context.info.payments;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
    {
        alias: ["т.к-оплате", "товары.к-оплате"],
        open: (context: ISaleTableContext): string | undefined => {
            if (!context.info.payments) {
                return undefined;
            }

            const value = context.info.goods.sum - context.info.discount - context.info.payments;
            return filters.money.moneyFormat(value, { locale: context.locale });
        },
    },
];
