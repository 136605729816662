import {
    IStore,
    IGoodCategory,
    IAccount,
    IGoodRegistrationCreateDto,
    IGoodRegistration,
    IGood,
    ITemplateLabel,
    ISupplier,
    IBarcode,
    ICompanyFeatureBarcodesUseCase,
    ICompanyFeatureBarcodes,
    BarcodeType,
    IGoodRegistrationItemDto,
} from "@lib";
import {
    Button,
    CheckBox,
    CheckBoxSelect,
    CheckBoxSelectChangedEventArgs,
    Control,
    Icon,
    Panel,
    Select,
    Tags,
    TextArea,
} from "@core/components/alt-ui/controls";
import { Modal } from "@core/components/alt-ui/modal";
import { Modal2 } from "@core/components/alt-ui/modal-2";
import { Footer } from "@core/controls/footer.control";
import { Table, TableColumn, TableFooter } from "@core/components/alt-ui/table";
import { GoodRegistrationCreateItemModal } from "./good-registration-create-item.modal";
import { AltMessage } from "@/utils/plugins/alt-message";
import { moneyFormat } from "@/filters/money";
import { GoodRegistrationGetItemModal } from "./good-registration-get-item.modal";
import { StringUtils } from "@/utils/string.utils";
import { ObjectUtils } from "@/utils/object.utils";
import { GoodRegistrationItemModal } from "./good-registration-item.modal";
import { barcodeList } from "@/@core/controls/barcode/barcode-control";

export interface IGoodRegistrationModalContext {
    companyId: string;
    userId: string;
    stores: IStore[];
    store?: IStore;
    accounts: IAccount[];
    categories: IGoodCategory[];
    suppliers: ISupplier[];
    labelTemplates: ITemplateLabel[];
    labelTemplatesSelected: ITemplateLabel[];
    showBarcode: boolean;
    goodRegistration?: IGoodRegistration;
    message: AltMessage;
    isDeductFromAccountSelected?: boolean;
    selectedAccountId?: string;
    barcodesFeature: ICompanyFeatureBarcodes | null;
    barcodesUseCase: ICompanyFeatureBarcodesUseCase;
    searchGoods?: (search: string, storeId: string) => Promise<IGood[]>;
    createHandler?: (
        dto: IGoodRegistrationCreateDto,
        labels: ITemplateLabel[],
        deductFromAccount: boolean,
        account?: IAccount,
    ) => Promise<boolean>;
    printHandler?: (labels: ITemplateLabel[], registration: IGoodRegistration) => void;
}

type OnCreateCallback = (
    dto: IGoodRegistrationCreateDto,
    labels: ITemplateLabel[],
    deductFromAccount: boolean,
    account?: string,
) => Promise<IGoodRegistration | null>;

type SupplierRef = { text: string; ref?: ISupplier };

export class GoodRegistrationModal extends Modal2<IGoodRegistrationModalContext, boolean> {
    private cbSupplier!: Select<SupplierRef>;
    private cbStore!: Select<IStore>;
    // private tbInvoiceId!: TextBox;
    // private dtDate!: DateTime;
    private tgTags!: Tags;

    private pnCreateGoodPanel!: Panel;
    private btnCreateGood!: Button;
    private btnGetGood!: Button;
    private btnAddGood!: Button;
    private tblGoodsTable!: Table<IGoodRegistrationItemDto>;

    private chDeductFromAccount!: CheckBox;
    private cbAccount!: Select<IAccount>;
    private taComment!: TextArea;

    private btnPrint!: Button;
    private cbsPrint!: CheckBoxSelect<ITemplateLabel>;
    private pnlPrint!: Panel;

    private ftFooter!: Footer;

    private context!: IGoodRegistrationModalContext;

    public createItemModal: GoodRegistrationCreateItemModal;
    public getItemModal: GoodRegistrationGetItemModal;
    public itemModal: GoodRegistrationItemModal;

    public constructor(id: string) {
        super(id, "");

        this.createItemModal = new GoodRegistrationCreateItemModal();
        this.createItemModal.onCreate = this.addGood.bind(this);
        this.createItemModal.onUpdate = this.editGood.bind(this);

        this.getItemModal = new GoodRegistrationGetItemModal();
        this.getItemModal.onAdd = this.addGood.bind(this);
        this.getItemModal.onEdit = this.editGood.bind(this);

        this.itemModal = new GoodRegistrationItemModal();
        this.itemModal.onAdd = this.addGood.bind(this);
        this.itemModal.onCreate = this.addGood.bind(this);

        this.initializeControls();
    }

    public get submodals(): Modal<any, any>[] {
        return [this.createItemModal, this.getItemModal, this.itemModal];
    }

    public get footer(): Footer {
        return this.ftFooter;
    }

    public show(context: IGoodRegistrationModalContext): Promise<boolean> {
        this.context = context;
        this.title = "Оприходование";

        this.initializeControls();

        if (this.context.goodRegistration) {
            this.title = `Оприходование № ${this.context.goodRegistration.number}`;
            this.populateControls(this.context.goodRegistration);
        }

        return super.show();
    }

    private getControlId(controlId: string): string {
        return `good-registration-modal.${controlId}`;
    }

    protected initializeControls(): void {
        this.cbSupplier = new Select<SupplierRef>();
        this.cbStore = new Select<IStore>();
        // this.tbInvoiceId = new TextBox();
        // this.dtDate = new DateTime();
        this.tgTags = new Tags();
        this.pnCreateGoodPanel = new Panel();
        this.btnCreateGood = new Button();
        this.btnGetGood = new Button();
        this.btnAddGood = new Button();
        this.tblGoodsTable = new Table<IGoodRegistrationItemDto>();
        this.taComment = new TextArea();
        this.chDeductFromAccount = new CheckBox();
        this.cbAccount = new Select();

        const suppliers: SupplierRef[] = this.context?.suppliers.map(s => ({ text: s.name, ref: s })) ?? [];
        suppliers.unshift({ text: "<Не указан>", ref: undefined });

        this.cbSupplier.id = this.getControlId("supplier");
        this.cbSupplier.label = "Поставщик";
        this.cbSupplier.items = suppliers;
        this.cbSupplier.textField = sr => sr.text;
        this.cbSupplier.descriptionField = sr => sr.ref?.description ?? "";
        this.cbSupplier.selectedIndex = 0;

        this.cbStore.id = "good.store";
        this.cbStore.label = "Склад";
        this.cbStore.items = this.context?.stores ?? [];
        this.cbStore.textField = ac => ac.info.name;
        this.cbStore.descriptionField = ac => ac.info.description;
        this.cbStore.selectedItem =
            this.cbStore.items.find(o => o.id === this.context?.store?.id) ??
            (this.cbStore.items.length > 0 ? this.cbStore.items[0] : null);

        // this.tbInvoiceId.id = this.getControlId("invoiceId");
        // this.tbInvoiceId.label = "Номер накладной";
        // this.tbInvoiceId.validation = "required";

        // this.dtDate.id = this.getControlId("date");
        // this.dtDate.label = "Дата";
        // this.dtDate.value = moment(new Date()).set("hours", 12).set("minutes", 0).format("YYYY-MM-DD HH:mm");
        // this.dtDate.validation = "required";

        this.tgTags.id = this.getControlId("tags");
        this.tgTags.label = "Метки";
        this.tgTags.help = "Добавить метки ко всем товарам";
        this.tgTags.placeholder = "Введите новую метку";

        //

        this.btnCreateGood.id = this.getControlId("createGood");
        this.btnCreateGood.text = "+ Новый товар";
        this.btnCreateGood.class = "mr-1";
        this.btnCreateGood.addClickHandler(() => this.clickCreateGood());

        this.btnGetGood.id = this.getControlId("getGood");
        this.btnGetGood.text = "+ Товар со склада";
        this.btnGetGood.class = "mr-1";
        this.btnGetGood.addClickHandler(() => this.clickGetGood());

        this.btnAddGood.id = this.getControlId("addGood");
        this.btnAddGood.text = "+ Товар";
        this.btnAddGood.class = "mr-1";
        this.btnAddGood.addClickHandler(this.clickAddGood.bind(this));

        this.pnCreateGoodPanel.id = this.getControlId("createGoodPanel");
        this.pnCreateGoodPanel.addControls([this.btnCreateGood, this.btnGetGood]);
        this.pnCreateGoodPanel.class = "flex justify-start mt-2";

        //

        this.tblGoodsTable.id = this.getControlId("goodsTable");
        this.tblGoodsTable.class = "mt-1";
        this.tblGoodsTable.columns = this.getTableColumns(false);
        this.tblGoodsTable.footer = this.getTableFooter();

        //

        this.chDeductFromAccount.id = this.getControlId("deductFromAccount");
        this.chDeductFromAccount.text = "Вычесть со счёта";
        this.chDeductFromAccount.class = "mt-0";
        this.chDeductFromAccount.value = this.context?.isDeductFromAccountSelected ?? true;

        this.cbAccount.id = this.getControlId("account");
        this.cbAccount.label = "Счёт";
        this.cbAccount.items = this.context?.accounts ?? [];
        this.cbAccount.textField = ac => ac.info.name;
        this.cbAccount.descriptionField = ac => ac.info.description;
        if (this.context?.isDeductFromAccountSelected && this.context?.selectedAccountId) {
            const index = this.cbAccount.items.findIndex(a => a.id === this.context.selectedAccountId);
            this.cbAccount.selectedIndex = index;
        } else {
            this.cbAccount.selectedIndex = this.cbAccount.items.length > 0 ? 0 : -1;
        }

        this.taComment.id = this.getControlId("comment");
        this.taComment.label = "Комментарий";

        this.btnPrint = new Button();
        this.btnPrint.id = this.getControlId("print.button");
        this.btnPrint.class = "mr-1";
        this.btnPrint.variant = "outline-primary";
        this.btnPrint.icon = new Icon();
        this.btnPrint.icon.icon = "PrinterIcon";
        this.btnPrint.addClickHandler(this.clickPrint.bind(this));

        this.cbsPrint = new CheckBoxSelect<ITemplateLabel>();
        this.cbsPrint.id = this.getControlId("print");
        this.cbsPrint.class = "w-full";
        this.cbsPrint.containerClass = "w-full";
        this.cbsPrint.items = this.context?.labelTemplates ?? [];
        this.cbsPrint.textField = d => d.name;
        this.cbsPrint.addChangedHandler(this.changeLabelTemplates.bind(this));
        this.cbsPrint.selectedItems = this.context?.labelTemplatesSelected ?? [];

        this.pnlPrint = new Panel();
        this.pnlPrint.class = "flex mt-1";
        this.pnlPrint.addControls([this.btnPrint, this.cbsPrint]);

        this.cbStore.disabled = false;
        this.tgTags.disabled = false;
        this.taComment.disabled = false;
        this.cbAccount.visible = true;
        this.chDeductFromAccount.visible = true;
        this.cbAccount.disabled = false;
        this.btnCreateGood.disabled = false;
        this.btnGetGood.disabled = false;

        this.ftFooter = new Footer({
            okText: this.context?.goodRegistration ? "" : "Оприходовать",
            okHandler: this.clickSave.bind(this),
            cancelHandler: this.clickCancel.bind(this),
        });
    }

    private populateControls(goodRegistration: IGoodRegistration): void {
        // this.tbInvoiceId.value = goodRegistration.invoiceId;
        // this.dtDate.value = goodRegistration.date;

        if (goodRegistration.supplier) {
            this.cbSupplier.selectedItem =
                this.cbSupplier.items.find(sr => sr.ref?.id === goodRegistration.supplier) ?? null;
        }
        this.cbSupplier.disabled = true;

        this.cbStore.selectedItem =
            this.cbStore.items.find(o => {
                return o.id === goodRegistration.store;
            }) ?? null;
        this.cbStore.disabled = true;

        this.tgTags.tags = goodRegistration.tags;
        this.tgTags.disabled = true;

        this.tblGoodsTable.items = goodRegistration.goods.map(g => {
            const good = ObjectUtils.clone(g.goodRef as IGood);
            good.info.cost = g.cost;
            good.info.quantity = g.quantity;
            return good as IGoodRegistrationItemDto;
        });

        this.tblGoodsTable.columns = this.getTableColumns(true);

        this.taComment.text = goodRegistration.comment;
        this.taComment.disabled = true;

        if (goodRegistration.account) {
            const index = this.cbAccount.items.findIndex(a => a.id === goodRegistration.account);
            this.cbAccount.selectedIndex = index;
        } else {
            this.cbAccount.visible = false;
        }

        this.chDeductFromAccount.visible = false;
        this.cbAccount.disabled = true;

        this.btnCreateGood.disabled = true;
        this.btnGetGood.disabled = true;
    }

    private async changeLabelTemplates(sender: any, e: CheckBoxSelectChangedEventArgs<ITemplateLabel>): Promise<void> {
        if (e.items.length === 0) {
            this.cbsPrint.label = "не печатать ценники";
            return;
        }

        const form = StringUtils.decline(e.items.length, ["вид ценников", "вида ценников", "видов ценников"]);
        this.cbsPrint.label = `напечатать ${e.items.length} ${form}`;
    }

    private getTableColumns(readonly: boolean): TableColumn<IGoodRegistrationItemDto>[] {
        const columns: TableColumn<IGoodRegistrationItemDto>[] = [
            {
                title: "Наименование",
                classHeader: "text-left text-xs",
                classCell: "text-left",
                cell: item => item.info.name,
            },
            {
                title: "Себестоимость",
                classHeader: "text-xs",
                cell: item => {
                    const locale = this.cbStore.selectedItem?.info.locale;
                    return moneyFormat(item.info.cost, { locale });
                },
            },
            {
                title: "Количество",
                classHeader: "text-xs",
                cell: item => item.info.quantity.toString(),
            },
            {
                title: "Сумма",
                classHeader: "text-xs",
                cell: item => {
                    const locale = this.cbStore.selectedItem?.info.locale;
                    return moneyFormat(item.info.cost * item.info.quantity, { locale });
                },
            },
        ];

        if (!readonly) {
            columns.unshift({
                width: 1,
                cell: (item, index) => {
                    const pnlActions = new Panel();
                    pnlActions.class = "flex";

                    const iconDelete = new Icon();
                    iconDelete.icon = "Trash2Icon";
                    iconDelete.class = "cursor-pointer hover:text-danger";
                    iconDelete.addClickHandler(async (s, e) => {
                        const confirm = await this.context.message.confirm(
                            `Вы уверены, что хотите удалить товар "${item.info.name}"?`,
                            "Удаление товара",
                            { acceptText: "Удалить" },
                        );
                        if (confirm) {
                            this.tblGoodsTable.items = this.tblGoodsTable.items.filter((_, i) => i !== index);
                        }
                    });

                    pnlActions.addControl(iconDelete);

                    const iconEdit = new Icon();
                    iconEdit.icon = "Edit3Icon";
                    iconEdit.class = "cursor-pointer hover:text-primary ml-0.5";
                    iconEdit.addClickHandler(() => (item.good ? this.clickGetGood(item) : this.clickCreateGood(item)));

                    pnlActions.addControl(iconEdit);

                    return pnlActions;
                },
            });
        }

        return columns;
    }

    private getTableFooter(): TableFooter<IGoodRegistrationItemDto>[] {
        return [
            {
                title: "Итого:",
                cell: items => {
                    const locale = this.cbStore.selectedItem?.info.locale;
                    const value = items.reduce((sum, item) => (sum += item.info.cost * item.info.quantity), 0);
                    return moneyFormat(value, { locale });
                },
            },
        ];
    }

    public get controls(): Control[] {
        const controls: Control[] = [
            this.cbSupplier,
            this.cbStore,
            // this.tbInvoiceId,
            // this.dtDate,
            this.tgTags,
            this.pnCreateGoodPanel,
            this.tblGoodsTable,
        ];

        if (this.context?.goodRegistration) {
            controls.push(this.cbAccount);
        } else {
            if (this.chDeductFromAccount.value) {
                controls.push(this.chDeductFromAccount, this.cbAccount);
            } else {
                controls.push(this.chDeductFromAccount);
            }
        }

        controls.push(this.taComment);

        if (this.context?.goodRegistration) {
            controls.push(this.pnlPrint);
        } else {
            controls.push(this.cbsPrint);
        }

        return controls;
    }

    private async clickCreateGood(orig?: IGoodRegistrationItemDto): Promise<void> {
        if (!this.cbStore.selectedItem) {
            return;
        }

        let feature;

        try {
            feature = await this.context.barcodesUseCase.get(this.context.companyId);
        } catch (e: any) {
            return;
        }

        await this.createItemModal.show({
            good: orig,
            companyId: this.context.companyId,
            categories: this.context.categories,
            stores: this.context.stores,
            selectedStore: this.cbStore.selectedItem,
            showBarcode: this.context.showBarcode,
            selectedType: feature?.typeForGoods,
            generateBarcode: this.generateBarcode.bind(this),
            updateBarcodeType: this.updateBarcodeType.bind(this),
        });
    }

    private async clickGetGood(orig?: IGoodRegistrationItemDto): Promise<void> {
        if (!this.cbStore.selectedItem || !this.context.searchGoods) {
            return;
        }

        await this.getItemModal.show({
            good: orig,
            store: this.cbStore.selectedItem,
            searchGoods: this.context.searchGoods.bind(this),
        });
    }

    private async clickAddGood(): Promise<void> {
        if (!this.cbStore.selectedItem || !this.context.searchGoods) {
            return;
        }

        let feature;

        try {
            feature = await this.context.barcodesUseCase.get(this.context.companyId);
        } catch (e: any) {
            return;
        }

        await this.itemModal.show({
            companyId: this.context.companyId,
            stores: this.context.stores,
            selectedStore: this.cbStore.selectedItem,
            categories: this.context.categories,
            showBarcode: this.context.showBarcode,
            selectedType: feature?.typeForGoods,
            searchGoods: this.context.searchGoods.bind(this),
            generateBarcode: this.generateBarcode.bind(this),
            updateBarcodeType: this.updateBarcodeType.bind(this),
        });
    }

    private addGood(dto: IGoodRegistrationItemDto): Promise<boolean> {
        this.tblGoodsTable.items.push(dto);

        return Promise.resolve(true);
    }

    private editGood(orig: IGoodRegistrationItemDto, dto: IGoodRegistrationItemDto): Promise<boolean> {
        orig.group = dto.group;
        orig.place = dto.place;
        orig.info = dto.info;
        orig.tags = dto.tags;
        orig.quantityThreshold = dto.quantityThreshold;

        return Promise.resolve(true);
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide(false);
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();

        if (!valid) {
            return;
        }

        if (!this.context?.createHandler || !this.cbStore.selectedItem || !this.tblGoodsTable.items.length) {
            return;
        }

        const amount = this.tblGoodsTable.items.reduce(
            (result, item) => (result += item.info.cost * item.info.quantity),
            0,
        );

        const dto: IGoodRegistrationCreateDto = {
            // invoiceId: this.tbInvoiceId.text,
            // date: moment(this.dtDate.value).toISOString(),
            goods: this.tblGoodsTable.items,
            storeId: this.cbStore.selectedItem.id,
            userId: this.context.userId,
            supplierId: this.cbSupplier.selectedItem?.ref?.id,
            amount,
            tags: this.tgTags.tags,
            comment: this.taComment.text,
        };

        if (this.chDeductFromAccount.value) {
            dto.accountId = this.cbAccount.selectedItem?.id;
            dto.transactionDescription = "Оприходование";
        }

        const labels = this.cbsPrint.selectedItems;

        const ok = await this.context.createHandler(
            dto,
            labels,
            this.chDeductFromAccount.value,
            this.cbAccount.selectedItem ?? undefined,
        );

        if (ok) {
            this.hide(true);
        }
    }

    private async clickPrint(sender: any, e: any): Promise<void> {
        if (!this.context?.printHandler || this.cbsPrint.selectedItems.length === 0 || !this.context.goodRegistration) {
            return;
        }

        this.context.printHandler(this.cbsPrint.selectedItems, this.context.goodRegistration);
    }

    private async generateBarcode(): Promise<IBarcode | null> {
        try {
            const feature = await this.context.barcodesUseCase.get(this.context.companyId);
            let selectedBarcodeIndex = barcodeList.findIndex(e => e.id === feature?.typeForGoods);
            selectedBarcodeIndex = selectedBarcodeIndex >= 0 ? selectedBarcodeIndex : 0;

            return await this.context.barcodesUseCase.generate(
                this.context.companyId,
                barcodeList[selectedBarcodeIndex].id,
            );
        } catch (e: any) {
            return null;
        }
    }

    private async updateBarcodeType(type: BarcodeType): Promise<void> {
        const feature = this.context.barcodesFeature;
        if (!feature) {
            return;
        }

        const updated: ICompanyFeatureBarcodes = {
            enabledForGoods: feature.enabledForGoods,
            typeForGoods: type,
        };

        try {
            await this.context.barcodesUseCase.update(this.context.companyId, updated);
        } catch {
            //
        }
    }
}
